@import "../../../global.scss";

.bottom-left {
    bottom: 12px;
    left: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.controlheight {
    height: 38px;
}

.error {
    color: #dc3545;
    font-size: 12px;
}

.nopadding {
    padding: 0px 0px 0px 0px !important;
}

.nowrap {
    white-space: nowrap;
}

.top-right {
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.top-left {
    top: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
}

.spinner {
    color: map-get($theme-colors, 'accent8');
    position: relative;
    left: 50%;
    z-index: 1000;
}