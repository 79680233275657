@import "../../../global.scss";

.editFieldBorder {
    border: 2px solid map-get($theme-colors, 'accent9');
    border-radius: .25rem;
    margin-top: 10px;
    margin-bottom: 10px;
}

.editFieldHeaderContent {
    align-items: center;
    font-weight: bold;
    padding: 10px;
    text-align: left;
}

.editFieldHeader {
    color: black;
    height: 45px;
    background-color: map-get($theme-colors, 'accent9');
}

.nowrap {
    white-space: nowrap;
}