@import "../../../global.scss";

.header {
    color: black;
    height: min-content;
    margin-left: ($spacer * .1) !important;
    margin-right: ($spacer * .1) !important;
    background-color: map-get($theme-colors, 'accent7');
}

.headerObjects {
    text-align: center;
    margin: auto;
    padding: 10px;
    height: min-content;
    font-weight: bold;
    align-items: center;
}

.acreageType {
    height: 51px;
}

.applyBtn {
    height: 51px;
}


.border {
    border: 1px solid map-get($theme-colors, 'accent7');
    margin-left: ($spacer * .1) !important;
    margin-right: ($spacer * .1) !important;
}

.regular {
    font-weight: normal;
    font-size: small;
}

.red {
    color: red;
    font-size: small;
    font-weight: normal;
}

.spinner {
    color: map-get($theme-colors, 'accent8');
    position: relative;
    left: 50%;
    z-index: 1000;
}

.nowrap {
    white-space: nowrap;
    text-align: center;
    align-items: center;
}
.rlu {
    text-align: center;
}