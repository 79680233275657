.confirmMessageModelFooter {
    display: flex;
    justify-content: space-between;
    width: 300px;

    .closeButton {
        min-width: 80px;
    }

    .okButton {
        min-width: 200px;
    }

    .okAlertBtnCenter {
        min-width: 100px;
    }
}
