.map-container {
    min-height: 400px;
    padding: 2px 2px 2px 2px;
    border: 1px solid grey;
    height: 100%;
    width: 100%;
}

.map-containernoMap {
    min-height: 150px;
    padding: 2px 2px 2px 2px;
    border: 1px solid grey;
    height: 50%;
    width: 100%;
    text-align: center;
}