@import "../../../global.scss";

.other {
    height: 51px;
}

.row {
    padding-left:0px;
    padding-right:0px;
}

.border {
    border: 1px solid map-get($theme-colors, 'accent7');
    margin-left: ($spacer * .1) !important;
    margin-right: ($spacer * .1) !important;
}

.cluBadge {
    border: 3px solid white;
}

.cluBadge:hover {
    border: 3px solid lightblue;
}