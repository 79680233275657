@import "../../global.scss";

.footerLink {
    color: #337ab7;

    &:hover {
        color: #23527c;
    }
}

.footerText {
    font-size: 12px;
}

.footer {
    padding: 20px 0;
    text-align: center
}
