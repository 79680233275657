@import "../../global.scss";

.clearButton, .clearButton:focus, .clearButton:active {
    background-color: #fff !important;
    color: black !important;
    border: none !important;
    font-size: .7rem !important;
}

.filterlabel, .filterlabel:focus {
    box-shadow: none !important;
    background-color: #93c4ca !important;
    border: none !important;
    font-size: .7rem !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color:#fff;
    margin-bottom: .5rem;
}

.filterlabelcond {
    color: #000 !important;
    box-shadow: none !important;
    background-color: #fff !important;
    border: none !important;
    font-size: .7rem !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    color:#fff;
    margin-bottom: .5rem;
}


.bg-93c, .bg-93c:focus, .bg-93c:active {
    background-color: #93c4ca !important;
    border: none !important;
    font-size: .7rem !important;
}

.cursor-pointer{
    cursor: pointer;
}

.fs-1-5{
    font-size: 1.5rem;
}

.me-5p{
    margin-left: 5px;
}
.c-green{
    color: #4097a1;
}

.po-t-3{
    position: relative;
    top:3px;
}