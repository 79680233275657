﻿/* Customize theme based on http://devint.hcch.com:9000/ */
$theme-colors: ( "primary": #d6822a, "secondary": #999999, "success": #466947, "info": #a2b6c3, "warning": #fcb426, "danger": #a22a2a, "light": #fcb426, "dark": #4097a1, "default":#483728, "accent1": #483728, "accent2": #a22a2a, "accent3": #466947, "accent4": #eff1ed, "accent5": #004c23, "accent6": #372d2c, "accent7": #cdd4cb, "accent8": #007681, "accent9": #e3e8ed, "accent10": #4d758c, "accent11": #b3bdae, "accent12": #93d50a, "accent13": #63c7c7);
$link-color: #0563c1;
$link-decoration: none;
$link-hover-decoration: underline;
$min-contrast-ratio: 2.0;
$btn-font-weight: 600;

/* import bootstrap */
@import "~bootstrap/scss/bootstrap";

html, body {
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 1.42857143em;
    color: #222;
    background-color: #fff;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 15px;
    margin-bottom: 20px
}

h1 {
    font-size: 2.5em;
    color: #275431;
    font-family: "ashburylight",serif;
    font-weight: normal
}

#mainContent {
    background: #fff;
}

.card-header {
    background: transparent;
}

.btn {
    text-transform: uppercase;
}

.btn-confirm {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    text-transform: none;
}

.btn-closeBtn {
    width: 100%;
    height: 38px;
    border-radius: 4px;
    text-transform: none;
}

.form-check-input {
	border-color: map-get($theme-colors, 'primary');
	&:checked {
		background-color: map-get($theme-colors, 'primary');
		border-color: map-get($theme-colors, 'primary');
	}
	&:focus {
		border-color: map-get($theme-colors, 'primary');
		box-shadow: 0 0 0 0.25rem rgba(map-get($theme-colors, 'primary'), 0.5);
	}
	&:disabled {
		opacity: 1;
	}
}

.switchInput {
	>.form-check-input {
		border-color: map-get($theme-colors, 'primary');
		background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28214,130,42,1.0%29%27/%3e%3c/svg%3e') !important;
		&:checked {
			background-color: map-get($theme-colors, 'primary');
			border-color: map-get($theme-colors, 'primary');
			background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255,255,255,1.0%29%27/%3e%3c/svg%3e') !important;
		}
	}
}

.accordion-button {
    background-color: map-get($theme-colors, 'accent5');
    color: #fff;
    height: 40px;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button.collapsed {
    background-color: map-get($theme-colors, 'accent5');
    color: #fff;
}

.accordion-button.collapsed::after {

    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    margin-left: auto;
}

.accordion-button:not(.collapsed) {
    background-color: map-get($theme-colors, 'accent5');
    color: #fff;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    margin-left: auto;
}

.accordion-item {
    border-color: map-get($theme-colors, 'accent5');
}

.change-indicator {
    border-left: 6px solid green;
    border-radius: 1px;
    margin-left: 3px;
    margin-top: 2px;
    margin-bottom: 2px
}

.disabled {
    color: #d9d9d9;
}

.nowrap {
    white-space: nowrap;
}

.hoverBackground:hover {
    background-color: rgb(225, 225, 225);
}

.alternatingBackground {
    background-color: rgb(236, 236, 236);
}