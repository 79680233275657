//edit modal
.edit-modal-header {
  height: 10px;
  background: #004c23;
}

.edit-modal-header div{
  font-size: 14px;
  font-weight: bold;
  color:white;
}

.btn-close-white {
    color: white;
}

.m-90w{
    max-width:90% !important;
}

.absolute-pos {
    position: absolute;
}

.mx-width-fit-content{
    max-width:fit-content !important;
}