@import "../../../global.scss";

.nowrap {
    white-space: nowrap;
}

.header {
    background-color: map-get($theme-colors, 'accent5');
    color: #fff;
    height: 45px;
}


.trashicon {
    font-size: 1.25rem;
    color: #fff;
    cursor: pointer;
}

.clickableCell {
    cursor: pointer;
}

.coveragetable {
    border: 1px solid map-get($theme-colors, 'accent5');
    width: fit-content;
}

th,
.th {
    padding: 2px 2px;
    position: relative;
    font-weight: bold;
    text-align: center;
    height: 30px;
}

.resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
}

.resizer.isResizing {
    background: blue;
    opacity: 1;
}

@media (hover: hover) {
    .resizer {
        opacity: 0;
    }

    *:hover > .resizer {
        opacity: 1;
    }
}

.validationPass {
    color: #466947
}

.validationFail {
    color: red
}

.error {
    color: #dc3545;
    font-size: 12px;
}

.viewFarmLandsCoverageDetailsHeader {
    background-color: map-get($theme-colors, 'accent5');
    color: white;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    border-top-left-radius: .25rem;
    border: 1px solid;
    border-color: map-get($theme-colors, 'accent5');
    padding-top: 8px;
}

.viewFarmLandsOptions {
    background-color: map-get($theme-colors, 'accent5');
    color: white;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    border-top-right-radius: .25rem;
    border: 1px solid;
    border-color: map-get($theme-colors, 'accent5');
    padding-top: 8px;
}

.viewFarmLandsError {
    color: white;
    border: 1px solid;
    border-bottom: none;
    border-color: map-get($theme-colors, 'accent5');
}
.viewFarmLand {
    border: 1px solid;
    border-color: map-get($theme-colors, 'accent5');
    border-top: none;
}

.viewFarmLandLocked {
    border: 1px solid;
    border-color: map-get($theme-colors, 'accent5');
    border-top: none;
    background-color: lightgray;
}

.nopadding {
    padding: 0px 0px 0px 0px !important;
}

:disabled {
    background-color: #d9d9d9 !important;
  }

.header {
    color: black;
    height: min-content;
    padding-top: 8px;
    background-color: map-get($theme-colors, 'accent7');
}

  .spinner {
    color: map-get($theme-colors, 'accent8');
    position: relative;
    left: 50%;
    z-index: 1000;
}