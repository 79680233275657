.form-control:disabled,
.form-control[readonly] {
  background-color: #d9d9d9 !important;
}
p {
  white-space: pre;
}
.popover{
  width:340px !important;
  max-width:340px !important; 
}
.table-striped > tbody > tr:nth-of-type(2n + 1) > * {
  --bs-table-accent-bg: #f5f5f5 !important;
}
.table > :not(caption) > * > * {
  background-color: #fff !important;
}
.coverageTableHeader {
  th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3) {
    width: 11%;
  }
  th:nth-child(4) {
    width: 8%;
  }
  th:nth-child(5) {
    width: 7%;
  }
  th:nth-child(6) {
    width: 10%;
  }
  th:nth-child(7) {
    width: 15%;
  }
}
.shareholdersummary-tr {
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(3) {
    width: 11%;
  }
  td:nth-child(4) {
    width: 8%;
  }
  td:nth-child(5) {
    width: 7%;
  }
  td:nth-child(6) {
    width: 10%;
  }
  th:nth-child(7) {
    width: 17.5%;
  }
  td:nth-child(8){
      width:8%;
  }
  td:nth-child(7) {
    width: 17.5%;
  }
  td:nth-child(8){
      width:8%;
  }
}

@media (min-width: 950px) and (max-width: 1000px) {
  .fix-grower-input-share td:last-child {
    width: 165px;
  }
  .pos-l-40 {
    position: relative;
    left: 40px;
  }
}
