.valueCss {
    text-align: center;
    pointer-events: none;
    user-select: none;
}

.labelCss {
    text-align: center;
    opacity: .65;
    transform: scale(0.85);
    pointer-events: none;
    user-select: none;
}
.minRowHeight{
    height:51px;
}

.clickable {
    cursor: pointer;
    height: 51px;
}