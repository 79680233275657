@import "../../../components/columnfilter/ColumnFilter.module.scss";

.d-flex{
    display: flex;
}

.justify-content-evenly{
    justify-content: space-evenly;
}

.justify-content-center{
    justify-content: center;
}

.align-items-center{
    align-items: center;
}

.margin-content{
    margin-top: 0.3rem !important;
    margin-bottom: 0.3rem !important
}

.first-column{
    color: blue;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.cursor-pointer{
    cursor: pointer;
}

.expcol-p{
    color: blue;
}


.bg-e9{
    background-color: #e9ecef;
}

.bg-f09{
    background-color: #f0e8e8;
}

.bg-f5{
    background-color: #f5f5f5;
}
.w-430{
    width: 430px;
}

.w-78-5{
    width: 78.5px;
}

.visibility-hidden{
    visibility: hidden;
}

.w-170-5{
    width: 170.5px;
}

.sharePadding{
    padding-right: .7rem;
}

.leftalign{
    left: 12px;
    position: relative;
}

.handleInpMargin {
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    position: relative;
    left: 20%;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.l-12{
    position: relative;
    left: 12%;
}

.inp-120{
    width:120px !important;
}

.handleInpMargin:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.inp-width{
    width:58px !important;
}
.inp-height{
    height:25px !important;
}
.errorIcon {
    align-self:center;
    position:relative;
    left:22%;
}
.handleSharePosition {
	position: relative;
	right: 21%;
}
.tr.bg-f5{
    position: relative;
}

@media (min-width:850px) and (max-width:1200px){
    .w-430{
        width: 230px;
    }
}


@media (min-width:850px) and (max-width:950px){
    .handleInpMargin {
        width: 100px;
      }
      .width50{
        width: 50%;
      }
}

.growerShareholderName {
	color: #fff;
	font-weight: bold;
	background: #0070ff;
	height: fit-content;
}

.growerRowMargin{
    margin-top: 1rem;
    margin-bottom: .7rem;
}

.margin-selectAll {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    margin-right: 2.2rem !important;
}
.width-11 {
    width:11%;
}
.readOnlyInputBox {
    background-color: white !important;
    pointer-events: none !important;
}

.minWidth-MaxContent {
    min-width: max-content;
}

.paddingLeftRight{
    padding-left:1rem !important;
    padding-right:1.5rem !important;
}