
.map-container {
    min-height: 400px;
    padding: 2px 2px 2px 2px;
    border: 1px solid grey;
    height: 100%;
    width: 100%;
}
.spinner {
    color: map-get($theme-colors, 'accent8');
    position: relative;
    left: 50%;
    z-index: 1000;
}

.porError {
    color: white;
    border: 1px solid;
    border-bottom: none;
    border-color: map-get($theme-colors, 'accent5');
    margin-left: 0.1rem !important;
    margin-right: 0.1rem !important;
}

.porRow {
    max-height: fit-content;
}

.porCoordinatesDiv {
    height: 360px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
}

.porGridRowHoverOver {
    background-color: lightyellow;
}

.porCoordinatesDiv::-webkit-scrollbar {
    width: 5px;
    height: 6px;
}

.porCoordinatesDiv::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.2);
}

.porPaddingLeft
{
    padding-left: 2.5em !important;
}